import { Store } from 'Store';
import axios from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [appState, setAppState] = useState({ isAdmin: false, loading: true });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const authUser = async () => {
      if (userInfo && userInfo.token) {
        setAppState({
          ...appState,
          loading: true,
        });
        axios
          .get('/api/users/auth', {
            headers: {
              Authorization: `Baerer ${userInfo.token}`,
            },
          })
          .then(() => {
            setAppState({
              isAdmin: true,
              loading: false,
            });
          })
          .catch(() => {
            setAppState({
              isAdmin: false,
              loading: false,
            });
          });
      } else {
        setAppState({
          isAdmin: false,
          loading: false,
        });
      }
    };
    authUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <AuthContext.Provider value={appState}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const state = useContext(AuthContext);
  if (state === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return { ...state };
};

export default useAuth;

export function lazyLoadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.media = 'print';
  link.onload = function () {
    this.media = 'all';
  };
  document.head.appendChild(link);
}

export function getSizeText(p) {
  if (p.sizeHat && p.sizeHat.length > 0) {
    if (p.sizeHat.length >= 2) {
      return `Hut-${Math.min(...p.sizeHat)}-${Math.max(...p.sizeHat)}`;
    } else {
      return `Hut-${p.sizeHat[0]}`;
    }
  } else {
    return `${p.size}`;
  }
}

export function useCurrentHeight() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { lastHeight } = state;

  const mainContainer = document.getElementById('mainContainer');

  const setHeight = useCallback(() => {
    ctxDispatch({
      type: 'CONTAINER_HEIGHT',
      payload: mainContainer.offsetHeight,
    });
  }, [ctxDispatch, mainContainer.offsetHeight]);

  return [lastHeight, setHeight];
}
