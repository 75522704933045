import axios from 'axios';
import UserCard from 'components/UserCard';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Store } from 'Store';
import { useCurrentHeight } from 'utils/utils';

const TrackingDashboardScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [lastHeight, setHeight] = useCurrentHeight();

  const [searchParams, setSearchParams] = useSearchParams();

  const [timespan, setTimespan] = useState(
    searchParams.get('timespan') ?? '1800'
  );
  const [sortorder, setSortorder] = useState(
    searchParams.get('sortorder') ?? 'lastInteraction'
  );

  useEffect(() => {
    const excludedUsers = [
      '64f8515b-676b-4bf2-9004-cea479306496',
      'b687e1d6-d1ea-4c41-a52f-1b344b3bc1bf',
      '9f6b9f1d-92a8-4ee6-8231-c773dfa38fe0',
      'c7bef466-5264-431d-834d-0734e52b3450',
      'af4699cd-5688-407e-a646-5ee1e61fdcd1',
      '18555d90-51a2-45c4-ba97-74bb148519e2',
      '118c6bb8-ac18-42b3-bcc8-04103f14d839',
    ];

    const fetchData = async () => {
      const { data } = await axios.get(`/api/users/interactions/${timespan}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setUsers(data.filter((user) => !excludedUsers.includes(user._id)));
      setHeight();
      setLoading(false);
    };
    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, 5000); // Fetch every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timespan, userInfo.token]);

  const timeSpanOptions = {
    '30 Minuten': 30 * 60,
    '2 Stunden': 2 * 60 * 60,
    '12 Stunden': 12 * 60 * 60,
    '1 Tag': 24 * 60 * 60,
    '1 Woche': 7 * 24 * 60 * 60,
    '1 Monat': 31 * 24 * 60 * 60,
  };

  const sortorderOptions = {
    'Letzte Interaktion': 'lastInteraction',
    Warenkorbwert: 'totalCartValue',
    'Benutzer-ID': 'trackingNumber',
  };

  return (
    <div style={{ minHeight: loading ? lastHeight : 0 }}>
      <div className="d-flex align-items-center mb-2">
        <h5 className="my-1 mx-2">{`Zeitraum:`}</h5>
        <Form>
          <Form.Select
            aria-label="select seller"
            onChange={(e) => {
              setTimespan(e.target.value);
              setSearchParams((searchParams) => {
                searchParams.set('timespan', e.target.value);
                return searchParams;
              });
            }}
            value={timespan}
            className="d-inline"
          >
            {Object.entries(timeSpanOptions).map(([k, v]) => {
              return (
                <option value={v} key={k}>
                  {k}
                </option>
              );
            })}
          </Form.Select>
        </Form>
      </div>
      <div className="d-flex align-items-center mb-2">
        <h5 className="my-1 mx-2">{`Sortierung:`}</h5>
        <Form>
          <Form.Select
            aria-label="select seller"
            onChange={(e) => {
              setSortorder(e.target.value);
              setSearchParams((searchParams) => {
                searchParams.set('sortorder', e.target.value);
                return searchParams;
              });
            }}
            value={sortorder}
            className="d-inline"
          >
            {Object.entries(sortorderOptions).map(([k, v]) => {
              return (
                <option value={v} key={k}>
                  {k}
                </option>
              );
            })}
          </Form.Select>
        </Form>
      </div>
      <div className="ms-2">{`Aktive Nutzer: ${users.length}`}</div>
      <div>
        {users
          .sort((a, b) => b[sortorder] - a[sortorder])
          .map((user) => (
            <UserCard key={user._id} user={user} />
          ))}
      </div>
    </div>
  );
};

export default TrackingDashboardScreen;
